import React, { CSSProperties, MouseEventHandler, useState } from "react";
import { colors, generalStyles } from "../../styles/globals";
import Spinner from "../Spinner";
import FormInput from "../FormInput";
import CereriCheckBoxs from "../Cereri/CheckBoxs";
import { graphql, useStaticQuery } from "gatsby";
import { cereriStyles } from "../Cereri/styles";
import { ALFA_NUMERIC_REGEX } from "../../utils/regex";
import { AccesConsumuriFormData } from "../../pages/accesConsumuri";
import { StaticImage } from "gatsby-plugin-image";

const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
  } as CSSProperties,
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 16,
    right: 24,
    position: "absolute",
    cursor: "pointer",
    // backgroundColor: "red",
  } as CSSProperties,
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 520,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.grayBackground,
    textAlign: "center",
  } as CSSProperties,
  loginButton: {
    height: 40,
    backgroundColor: colors.main,
    color: colors.white,
    padding: "0px 16px",
    fontSize: 20,
    textAlign: "center",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
  } as CSSProperties,
};

const checkFormData = (formData) => {
  if (formData.isCasnic) return formData.clc.length > 0 && formData.codClient.length > 0;
  else return formData.codClient.length > 0;
};

type PropTypes = {
  formData: AccesConsumuriFormData;
  setFormData: React.Dispatch<React.SetStateAction<AccesConsumuriFormData>>;
  tryAuth: (formData: AccesConsumuriFormData) => void;
  infoMsg: string;
  showCerereAsociereClc: MouseEventHandler<HTMLAnchorElement>;
};
export default function AuthForm({ formData, setFormData, tryAuth, infoMsg, showCerereAsociereClc }: PropTypes) {
  const [showInputErrors, setShowInputErrors] = useState(false);
  const handleInputChange = (value: string, name: string) => {
    // console.log("Changing form value: ", value, name)
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: value,
    }));
  };

  const submitForm = () => {
    tryAuth(formData);
  };

  const handleFormKeyPressed = (event) => {
    if (event.charCode === 13) submitForm();
  };

  return (
    // <div style={styles.modalContainer} >
    <div className="containerBoxShadow" style={styles.loginContainer} onKeyPress={handleFormKeyPressed}>
      <div style={{ display: "block", width: 180, margin: "0 auto" }}>
        <StaticImage style={{ marginTop: 32 }} layout="fullWidth" src="../../images/logo.png" alt="Logo Design-Proiect" />
      </div>

      <div id="loginForm" style={{ marginTop: 32, display: "block" }}>
        <h1 style={{ color: colors.main }}>Acces consumuri</h1>
        <div>
          <span
            style={{
              width: "50%",
              float: "left",
              marginLeft: 0,
            }}
          >
            <input
              type="checkbox"
              id="isCasnic"
              name="isCasnic"
              checked={formData.isCasnic}
              onChange={() => {
                setFormData((oldFormData) => ({
                  clc: "",
                  codClient: "",
                  isCasnic: true,
                }));
                setShowInputErrors(false);
              }}
            />
            <label style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }} htmlFor="isCasnic">
              Client final
            </label>
          </span>
          <span
            style={{
              width: "50%",
              float: "left",
              marginLeft: 0,
            }}
          >
            <input
              type="checkbox"
              id="notisCasnic"
              name="notisCasnic"
              checked={!formData.isCasnic}
              onChange={() => {
                setFormData((oldFormData) => ({
                  clc: "",
                  codClient: "",
                  isCasnic: false,
                }));
                setShowInputErrors(false);
              }}
            />
            <label style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }} htmlFor="notisCasnic">
              Furnizor
            </label>
          </span>
        </div>
        <FormInput
          key={formData.isCasnic ? "CCOD" : "FCOD"}
          containerStyle={{ ...generalStyles.inputContainer, marginTop: 16 }}
          style={generalStyles.input}
          errorStyle={{ ...generalStyles.inputErrorMsg, marginLeft: 50 }}
          placeholder="Cod client"
          name="codClient"
          onChange={handleInputChange}
          value={formData.codClient}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage="Introduceți codul dvs. de client"
          showWithoutTouched={showInputErrors}
        />
        {formData.isCasnic ? (
          <FormInput
            containerStyle={{
              ...generalStyles.inputContainer,
              marginTop: 32,
            }}
            style={generalStyles.input}
            errorStyle={{ ...generalStyles.inputErrorMsg, marginLeft: 50 }}
            placeholder="Clc-ul locului de consum"
            name="clc"
            onChange={handleInputChange}
            value={formData.clc}
            errorTest={ALFA_NUMERIC_REGEX}
            errorMessage="Introduceți clc-ul locului de consum"
            showWithoutTouched={showInputErrors}
          />
        ) : (
          <FormInput
            containerStyle={{
              ...generalStyles.inputContainer,
              marginTop: 32,
            }}
            style={generalStyles.input}
            errorStyle={{ ...generalStyles.inputErrorMsg, marginLeft: 50 }}
            placeholder="Număr contract distribuție"
            name="nrContractDist"
            onChange={handleInputChange}
            value={formData.nrContractDist}
            errorTest={ALFA_NUMERIC_REGEX}
            errorMessage="Introduceți numărul contractul de distribuție"
            showWithoutTouched={showInputErrors}
          />
        )}
        {!formData.isCasnic && (
          <div style={{ marginTop: 24 }}>
            <a
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: colors.main,
              }}
              onClick={showCerereAsociereClc}
            >
              Nu ai cont sau vrei să asociezi temporar un clc
            </a>
          </div>
        )}
      </div>
      {infoMsg !== "Vă rugăm așteptați" && (
        <div
          style={{
            marginTop: infoMsg ? 16 : 32,
            textAlign: "center",
            marginBottom: 16,
          }}
          onMouseEnter={() => setShowInputErrors(true)}
        >
          <button className="buttonHover textButtonHover" style={styles.loginButton} onClick={submitForm} disabled={!checkFormData(formData)}>
            Download consumuri
          </button>
        </div>
      )}
      {infoMsg && (
        <>
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          <div
            style={{
              padding: "30px 60px",
              // marginTop: 32,
              textAlign: "center",
              fontSize: 18,
              color: infoMsg === "Vă rugăm așteptați" ? colors.main : infoMsg.includes("reușită") ? colors.green : colors.red,
            }}
          >
            {infoMsg}
          </div>
        </>
      )}
    </div>
    // </div>
  );
}
