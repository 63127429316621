import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../styles/globals";
import svgs from "../svgs";
import { useFileInput } from "../Hooks/useFileInput";
import FilesList from "./FilesList";

const styles = {
  uploadFiles: {
    minWidth: 240,
    maxWidth: 480,
    width: "30%",
    padding: "8px 20px",
    backgroundColor: colors.accent,
    borderRadius: 8,
    color: colors.white,
    cursor: "pointer",
    lineHeight: "normal",
    fontSize: 18,
  },
};

export function calcFilesSize(files) {
  return files.reduce((acc, curentValue) => acc + curentValue.size, 0) * 0.000001;
}

export default function MultiFileUploader({ filesChanged }) {
  const inputFileRef = useRef(null);
  const [maxFilesSizeExceeded, setMaxFilesSizeExceeded] = useState(false);

  const { files, removeFile, resetFiles, changeFiles, uploadFiles } = useFileInput(inputFileRef);

  useEffect(() => {
    if (filesChanged) {
      if (files.reduce((acc, value) => acc + value.size, 0) * 0.000001 > 14.99) setMaxFilesSizeExceeded(true);
      else setMaxFilesSizeExceeded(false);
      console.log(files.reduce((acc, value) => acc + value.size, 0));
      filesChanged(files);
    }
  }, [files]);

  return (
    <>
      {files.length > 0 && <FilesList items={files} labelKey="name" onRemove={removeFile} />}
      <input
        type="file"
        id="file"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={changeFiles}
        multiple
        accept="image/*,.pdf"
        // value={files[0] ? files[0].name : null}
        // files={files}
      />
      <button type="button" className="subMenuHover" style={styles.uploadFiles} onClick={uploadFiles}>
        Încarcă documente
        <div
          style={{
            width: 24,
            height: 24,
            marginLeft: 16,
            display: "inline-block",
            color: colors.white,
            lineHeight: "normal",
            verticalAlign: "middle",
          }}
        >
          {svgs.addFile}
        </div>
      </button>
    </>
  );
}
