import React from "react";
import { colors } from "../../styles/globals";
import { cereriStyles } from "./styles";

const styles = {
  link: {
    color: colors.main,
    fontWeight: "bold",
    textDecoration: "none",
  },
};

export default function CustomCheckInput({ id, checked, onChange, showError, labelText, isPolicyCheck = false }) {
  return (
    <>
      <input type="checkbox" id={id} name={id} style={{ float: "left", marginTop: 4 }} checked={checked} onChange={onChange} />
      <label
        style={{
          paddingLeft: 10,
          ...cereriStyles.textLabelStyle,
          verticalAlign: "middle",
          lineHeight: "normal",
          fontSize: "20px",
          fontWeight: "normal",
          display: "inline-block",
          float: "left",
          width: "calc(100% - 14px)",
          color: showError ? colors.red : colors.main,
          cursor: "pointer",
        }}
        htmlFor={id}
        // onClick={onChange}
      >
        {isPolicyCheck ? (
          <span>
            Sunt de accord cu{" "}
            <a
              style={{
                ...styles.link,
                color: showError ? colors.red : colors.main,
              }}
              target="_blank"
              href="/documente/Terms&Conditions.pdf"
            >
              <span>termeni și condițiile de utilizare</span>
            </a>{" "}
            și cu prelucrarea{" "}
            <a
              style={{
                ...styles.link,
                color: showError ? colors.red : colors.main,
              }}
              target="_blank"
              href="/documente/PrivacyPolicy.pdf"
            >
              <span>prelucrarea datelor cu caracter personal</span>
            </a>{" "}
            de către platforma Design-Proiect
          </span>
        ) : (
          labelText
        )}
      </label>
    </>
  );
}
