import React, { ChangeEventHandler, useEffect, useState } from "react";

export function useFileInput(inputFileRef: React.MutableRefObject<HTMLInputElement> | null) {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (inputFileRef.current)
      setFiles((oldFiles) => {
        for (var i in inputFileRef.current.files) {
          console.log(inputFileRef.current.files[i]);
          if (typeof inputFileRef.current.files[i] === "object") oldFiles.push(inputFileRef.current.files[i]);
        }
        return [].concat(oldFiles);
      });
  }, []);

  const changeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    console.log(files);
    setFiles((oldFiles) => {
      console.log(oldFiles);
      for (var i in files) {
        console.log(files[i]);
        if (typeof files[i] === "object") oldFiles.push(files[i]);
      }
      return [].concat(oldFiles);
    });
    // event.target.value = ""
  };

  const resetFiles = () => {
    setFiles([]);
  };

  const removeFile = (fileName: string, fileIndex: number) => {
    inputFileRef.current.value = "";
    setFiles((oldFiles) => {
      return oldFiles.filter((item, index) => item.name !== fileName || index !== fileIndex);
    });
  };

  const uploadFiles = () => {
    inputFileRef.current.click();
  };

  if (inputFileRef.current) inputFileRef.current.value = "";

  return {
    files,
    changeFiles,
    resetFiles,
    removeFile,
    uploadFiles,
  };
}
