import { navigate } from "@reach/router";

export const apiBase =
  typeof window !== `undefined` && (window.location.host.includes("localhost") || window.location.host.includes("192.168"))
    ? "http://localhost:3015"
    : "https://api.design-proiect.ro";
export interface DatabaseObject {
  id: string;
}

export interface ApiResponse<T> {
  type: boolean;
  data: T;
}

export interface ApiFilesResponse<T> extends ApiResponse<T> {
  filesToLarge: boolean;
}

function logOutUserOn403Response() {
  // logout();
  navigate("/");
}

export function checkStatus<T>(response: Response): Promise<T> {
  // console.log('Response:', response);
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  if (response.status === 403) logOutUserOn403Response();
  return Promise.reject({
    message: "Api error",
    statusCode: response.status,
    data: response.body,
  });
}

export function checkStatusForBlob(response: Response): Promise<Blob> {
  // console.log('Response:', response);
  if (response.status >= 200 && response.status < 300) {
    return response.blob();
  }
  if (response.status === 403) logOutUserOn403Response();
  return Promise.reject({
    message: "Api error",
    statusCode: response.status,
    data: response.body,
  });
}

export function customGet<T>(url: string): Promise<T> {
  const loginData = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}

export function customPut<T, U>(url: string, data: U): Promise<T> {
  const loginData = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}

export function customPost<T, U>(url: string, data: U): Promise<T> {
  const loginData = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}
