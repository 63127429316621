import React from "react"
import { cereriStyles } from "./styles"

export default function CereriCheckBoxs({
  title,
  nameIs,
  labelIs,
  labelIsNot,
  setFormData,
  value,
  horizontal = true,
}) {
  const textWidth = horizontal ? "60%" : "100%"
  const inputWidth = horizontal ? "20%" : "100%"
  return (
    <>
      <span
        style={{
          width: textWidth,
          float: "left",
          ...cereriStyles.textLabelStyle,
        }}
      >
        {title}
      </span>
      <span
        style={{
          width: inputWidth,
          float: "left",
          marginLeft: horizontal ? 0 : 10,
        }}
      >
        <input
          type="checkbox"
          id={nameIs}
          name={nameIs}
          checked={value}
          onChange={() =>
            setFormData(oldFormData => ({
              ...oldFormData,
              [nameIs]: true,
            }))
          }
        />
        <label
          style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
          htmlFor={nameIs}
        >
          {labelIs}
        </label>
      </span>
      <span
        style={{
          width: inputWidth,
          float: "left",
          marginLeft: horizontal ? 0 : 10,
        }}
      >
        <input
          type="checkbox"
          id={"not" + nameIs}
          name={"not" + nameIs}
          checked={!value}
          onChange={() =>
            setFormData(oldFormData => ({
              ...oldFormData,
              [nameIs]: false,
            }))
          }
        />
        <label
          style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
          htmlFor={"not" + nameIs}
        >
          {labelIsNot}
        </label>
      </span>
    </>
  )
}
