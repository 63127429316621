import React from "react";
import { colors } from "../styles/globals";
import svgs from "./svgs";

export default function Steps({ steps, title, step, goBack }) {
  return (
    <div style={{ width: "100%", height: 100, flexDirection: "column" }}>
      <div style={{ display: "flex", flex: 1, height: 50, textAlign: "center" }}>
        {step > 0 && step < steps.length && (
          <div
            style={{
              position: "absolute",
              top: 24,
              left: 40,
              width: 16,
              height: 16,
              cursor: "pointer",
            }}
            onClick={goBack}
          >
            {svgs.back}
          </div>
        )}
        {steps.map((item, index) => (
          <span
            style={{
              flex: 1,
              // backgroundColor: "red",
              overflowX: "hidden",
              overflowY: "hidden",
              // backgroundColor: colors.grayBackground,
            }}
          >
            <div
              className={index < steps.length - 1 ? "dotsAfter" : "dotsBefore"}
              style={{
                height: 48,
                width: 48,
                display: "inline-block",
                marginTop: 1,
                borderRadius: "50%",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "48px",
                textAlign: "center",
                color: colors.main,
                border: "1px solid " + (index === step ? colors.main : colors.grayBackground),
              }}
            >
              {index >= step && index + 1 + "/" + steps.length}
              {/* {index < step && ""} */}
              {index < step && svgs.checked}
            </div>
            {/* <span class="dots" style={{ overflowX: "hidden" }} /> */}
          </span>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          height: 50,
          textAlign: "center",
          fontSize: "20px",
          lineHeight: "50px",
          fontWeight: "700",
          color: colors.main,
        }}
      >
        {title}
      </div>
    </div>
  );
}
