import React from "react";
import { colors } from "../../styles/globals";
import ReturnHomeButton from "./ReturnHomeButton";
import { cereriStyles } from "./styles";

export default function InfoSteps({ title, steps }) {
  return (
    <>
      <div style={cereriStyles.row}>
        <span
          style={{
            ...cereriStyles.textLabelMultiline,
            color: colors.green,
            paddingTop: 2,
          }}
        >
          {title}
        </span>
      </div>
      {steps.map((item) => (
        <div key={item} style={{ ...cereriStyles.row, paddingTop: 2 }}>
          <span style={cereriStyles.textLabelMultiline}>{item}</span>
        </div>
      ))}
      <div style={{ ...cereriStyles.row, textAlign: "center" }}>
        <ReturnHomeButton />
      </div>
    </>
  );
}
