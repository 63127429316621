import { apiBase, checkStatus, customPost, ApiFilesResponse } from "./utils";

const baseUrl = `${apiBase}/api/cerereAsociereClc`;

export function postCerereAsociereClc(data): Promise<ApiFilesResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  };
  return fetch(baseUrl, loginData).then(checkStatus) as Promise<ApiFilesResponse<null>>;
}
