import { AccesConsumuriFormData } from "../../pages/accesConsumuri";
import { apiBase, ApiResponse, checkStatusForBlob, customGet, customPost } from "./utils";

const baseUrl = `${apiBase}/api/accesConsumuri`;

export function postAuthClient(data: AccesConsumuriFormData): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${baseUrl}/authClient`, loginData).then(checkStatusForBlob) as Promise<ApiResponse<null>>;
}

export function postAuthFurnizor(data: AccesConsumuriFormData): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${baseUrl}/authFurnizor`, loginData).then(checkStatusForBlob) as Promise<ApiResponse<null>>;
}

// export function postGenerateExcellConsumuri(data) {
//   const loginData = {
//     method: "post",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   };
//   return fetch(`${baseUrl}/generateExcellConsumuri`, loginData).then(checkStatusForBlob) as Promise<ApiResponse<null>>;
// }
