import { CSSProperties } from "react";

export const colors = {
  white: "#FFFFFF",
  main: "#1d5e8c",
  accent: "#7D96A6",
  mainGray: "#6F8693",
  secondGray: "#4C6D7F",
  menuHover: "#B1C1C8",
  loginModalBackground: "#B3C1C8",
  textGrey: "#B3C1C8",
  orange: "#FCBF4A",
  newOrange: "#F5941E",
  red: "#F91616",
  green: "#009606",
  gray: "#C4C4C4",
  grayBackground: "#e7e7e7",
};

export const generalStyles = {
  fullContainer: { width: "100vw", height: "100vh", overflowY: "auto" } as CSSProperties,
  screenContainer: {
    position: "absolute",
    width: "56vw",
    // maxHeight: "98vh",
    // height: 960,
    top: 100,
    left: "50%",
    // paddingTop: 80,
    marginTop: "calc(-45%-80px)",
    marginLeft: "-28vw",
    height: "calc(85vh - 56px)",
    backgroundColor: colors.white,

    // overflowY: "auto",
  } as CSSProperties,
  pageTitle: {
    width: "56vw",
    zIndex: 1,
    height: 80,
    backgroundColor: colors.main,
    textAlign: "center",
    fontSize: 32,
    fontWeight: 700,
    color: colors.white,
    lineHeight: "80px",
  } as CSSProperties,
  restOfPageContainer: {
    width: "100%",
    // paddingTop: 80,
    overflowY: "auto",
    overflowX: "hidden",
  } as CSSProperties,
  inputContainer: {
    // width: 380,
    // height: 48,
    position: "relative",
    display: "inline-block",
  } as CSSProperties,
  input: {
    width: 380,
    height: 48,
    borderRadius: 4,
    // margin: "0 auto",
    border: 0,
    padding: 0,
    color: colors.main,
    fontSize: 20,
    letterSpacing: 0.2,
    paddingLeft: 20,
    textAlign: "left",
    overflowY: "hidden",
  } as CSSProperties,
  inputErrorMsg: {
    // width: 380,
    float: "left",
    display: "inline-block",
    marginLeft: 0,
    color: colors.red,
    fontSize: 16,
    textAlign: "left",
    lineHeight: "normal",
    marginTop: 4,
    // marginBottom: 8,
  } as CSSProperties,
  selectClc: {
    container: {
      height: 56,
      padding: "0px 220px",
      cursor: "pointer",
      position: "relative",
      // zIndex: 2,
    } as CSSProperties,
    homeIcon: {
      float: "left",
      width: 50,
      // height: 56,
      paddingTop: 4,
    } as CSSProperties,
    textContainer: {
      float: "left",
      height: 56,
      width: "calc(100% - 50px)",
      fontSize: 20,
      fontWeight: 700,
      color: colors.main,
      lineHeight: "56px",
      textAlign: "center",
    } as CSSProperties,
    dropDownIcon: {
      float: "left",
      width: 24,
      paddingTop: 16,
    } as CSSProperties,
    clcItemContainer: {
      display: "inline-block",
      backgroundColor: colors.white,
      height: 56,
      width: "100%",
      fontSize: 16,
      lineHeight: "56px",
      textAlign: "center",
      color: colors.main,
      cursor: "pointer",
    } as CSSProperties,
  },
};
