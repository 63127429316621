import { navigate } from "gatsby-link";
import React from "react";
import { cereriStyles } from "./styles";

export default function ReturnHomeButton({}) {
  return (
    <button
      style={{ ...cereriStyles.button, float: "none" }}
      onClick={() => {
        window.location.replace("/accesConsumuri");
      }}
      className="buttonHover textButtonHover"
    >
      Întoarcete la pagina principală
    </button>
  );
}
