import React, { CSSProperties, useEffect, useState } from "react";
import svgs from "./svgs";

const styles = {
  errorStyles: {
    // backgroundColor: "#DFA3A3",
    border: "1px solid #E81010",
  },
  passwordIcon: {
    position: "absolute",
    right: 12,
    top: 12,
    width: 24,
    height: 24,
    cursor: "pointer",
  },
};

type PropTypes = {
  containerStyle: CSSProperties;
  className?: string;
  style: CSSProperties;
  type?: string;
  disabled?: boolean;
  placeholder: string;
  name: string;
  onChange: (value: string, name: string) => void;
  value: string;
  isRequired?: boolean;
  errorTest?: (value: string) => boolean;
  errorMessage?: string;
  requiredMessage?: string;
  errorStyle?: CSSProperties;
  showErrorText?: boolean;
  showWithoutTouched?: boolean;
  maxLength?: number;
  passwordIconStyle?: CSSProperties;
};

export default function FormInput({
  containerStyle,
  className,
  style,
  type,
  disabled = false,
  placeholder,
  name,
  onChange,
  value,
  isRequired = true,
  errorTest,
  errorMessage,
  requiredMessage = "Câmp obligatoriu",
  errorStyle = { marginTop: 8, marginBottom: 8 },
  showErrorText = true,
  showWithoutTouched = false,
  maxLength = 40,
  passwordIconStyle,
}: PropTypes) {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(isRequired ? requiredMessage : "");
  const [formType, setFormType] = useState(type);

  useEffect(() => {
    if (isRequired && (!value || value === "")) setError(requiredMessage);
    else if (errorTest)
      if (errorTest(value)) setError("");
      else setError(errorMessage);
    else setError("");
  }, [isRequired, value, errorMessage]);

  const makeFormVisible = () => {
    setFormType("text");
  };
  const makeFormInvisible = () => {
    setFormType("password");
  };

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    // if (isRequired && value === "") setError(requiredMessage)
    // else if (errorTest)
    //   if (errorTest(value)) setError("")
    //   else setError(errorMessage)
    // else setError("")
    onChange(value, name);
  };

  const handleBlur = () => {
    setTouched(true);
    if (isRequired && (!value || value === "")) setError(requiredMessage);
    else if (errorTest)
      if (errorTest(value)) setError("");
      else setError(errorMessage);
    else setError("");
  };

  // console.log(error)

  let inputStyles;
  if ((touched || showWithoutTouched || (value && value.length > 0)) && error) inputStyles = { ...style, ...styles.errorStyles };
  else inputStyles = style;
  return (
    <div style={containerStyle}>
      <input
        disabled={disabled}
        className={className}
        style={inputStyles}
        type={formType}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleInputChange}
        onBlur={!touched ? handleBlur : undefined}
        maxLength={maxLength}
        spellCheck={false}
      ></input>
      {type === "password" && !disabled && (
        <div
          style={{ ...styles.passwordIcon, ...passwordIconStyle } as CSSProperties}
          onClick={formType === "password" ? makeFormVisible : makeFormInvisible}
        >
          {formType === "password" ? svgs.hidden : svgs.visible}
        </div>
      )}
      {(touched || showWithoutTouched || (value && value.length > 0)) && error && showErrorText && <div style={errorStyle}>{error}</div>}
    </div>
  );
}
