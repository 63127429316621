import React from "react";
import { colors } from "../../styles/globals";
import svgs from "../svgs";
import { cereriStyles } from "./styles";

type PropTypes = {
  items: File[] | string[];
  labelKey?: string;
  onRemove?: (name: string, index: number) => void;
};
export default function FilesList({ items, labelKey, onRemove }: PropTypes) {
  return (
    <div style={cereriStyles.fileListContainer}>
      <ul style={cereriStyles.filesList}>
        {items.map((item, index) => (
          <li key={labelKey ? item[labelKey] : item} style={cereriStyles.filesListItem}>
            {labelKey ? item[labelKey] : item}
            {onRemove && (
              <div
                style={{
                  float: "right",
                  width: 44,
                  padding: "0px 15px",
                  height: 24,
                  marginRight: 40,
                  cursor: "pointer",
                  fill: colors.main,
                }}
                onClick={() => {
                  onRemove(item.name, index);
                }}
              >
                {svgs.closeIcon}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
