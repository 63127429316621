import React, { useRef, useState } from "react";
import PageWithBackgroundImage from "../components/pageWithBackgroundImage";
import AuthForm from "../components/AccesConsumuri/authForm";
import { postAuthClient, postAuthFurnizor } from "../utils/api/accesConsumuri";
import FileSaver from "file-saver";
import CerereAsociereClc from "../components/AccesConsumuri/CerereAsociereClc";
import { Helmet } from "react-helmet";
import { ApiResponse } from "../utils/api/utils";

export type AccesConsumuriFormData = {
  isCasnic: boolean;
  clc?: string;
  codClient: string;
  nrContractDist?: string;
};
export default function Home({ location }) {
  const [step, setStep] = useState(0);
  const [infoMsg, setInfoMsg] = useState(null);
  const [formData, setFormData] = useState<AccesConsumuriFormData>({
    isCasnic: true,
    clc: "",
    codClient: "",
    nrContractDist: "",
  });

  const [showCerere, setShowCerere] = useState(false);
  // const [addClcData,setAddClcData] = useState({

  // })

  const dateFurnizor = useRef(null);
  const tryAuth = async () => {
    try {
      setInfoMsg("Vă rugăm așteptați");
      let apiResponse: ApiResponse<null>;
      if (formData.isCasnic) apiResponse = await postAuthClient(formData);
      else apiResponse = await postAuthFurnizor(formData);
      if (apiResponse) {
        FileSaver.saveAs(apiResponse, "ExportConsumuri.xlsx");
        dateFurnizor.current = apiResponse.data;
        setInfoMsg("Autentificare reușită. Fișierul excel va fi downloadat în căteva momente.");
      }
    } catch (error) {
      setInfoMsg("Eroare cerere. Vă rugăm reîncercați");
    }
  };

  const showCerereAsociereClc = () => {
    setShowCerere(true);
    setStep(-1);
  };

  const addNewClc = () => {
    setShowCerere(true);
    setStep(-1);
  };

  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <title>Acces consumuri - Design-Proiect</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {step === 0 && (
        <AuthForm
          formData={formData}
          setFormData={setFormData}
          tryAuth={tryAuth}
          infoMsg={infoMsg}
          // closeForm={closeForm}
          showCerereAsociereClc={showCerereAsociereClc}
        />
      )}
      {showCerere && <CerereAsociereClc datePrecompletate={dateFurnizor.current} />}
    </PageWithBackgroundImage>
  );
}
